@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .button {
    @apply bg-primary text-white p-3 px-4 rounded-lg block max-w-max cursor-pointer hover:text-white font-semibold hover:scale-105 transition-all duration-200;
  }
  .cfw-feature {
    @apply bg-white rounded-xl flex flex-col items-center justify-center py-8 lg:py-0 mb-6 lg:mb-0;
  }
  .hover-text {
    @apply hover:text-white hover:underline;
  }
}

body {
  margin: 0;
  font-family: "Inter", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 3rem;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.25px;
  color: #000;
}

h3 {
  font-family: "Inter";
  font-size: 2rem;
  font-weight: 400;
  line-height: 40px;
}

h5 {
  font-size: 1.5rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: #000;
}

h6 {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

span {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

p {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #1a1a1a;
  margin-bottom: 0;
}

.primary-font {
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 24px;
}

@media (max-width: 575.98px) {
  h1 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.125rem;
    line-height: 22.23px;
  }
}
