.hero-img {
  height: 85%;
}
.mrb-2 {
  margin-bottom: 2rem;
}
.mrt-2 {
  margin-top: 2rem !important;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.pl-0 {
  padding-left: 0;
}
.herosection {
  position: relative;
}
.heading-max {
  max-width: 24ch;
  text-align: center;
}
.sectionpara-max {
  max-width: 44ch;
  text-align: center;
  margin: auto;
}

.bigbillionimgContainer {
  position: absolute;
  top: 74%;
  right: 140px;
  width: fit-content;
}
.bigbillionimgContainer img {
  height: 320px;
}
.innerpageconatiner {
  position: absolute;
  top: 74%;
  right: 140px;
  width: fit-content;
}
.innerpageconatiner img {
  height: 320px;
}
.navbar-brand img {
  height: 60px;
}

.top-mt {
  margin: 180px 0;
}
/* .CustomHeight{
  height: 351px;
} */
.left-text {
  font-size: 36px;
  color: #000;
}
.right-text {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.custom-li {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.15px;
  color: #00000099;
  margin-bottom: 0;
}

.custom-li img {
  height: 30px;
}

.btn-primary {
  font-family: "Inter";
  font-size: 15px;
  font-weight: 500;
  background-color: #3366cc;
  color: white;
  padding: 8px 40px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.btn-primary:hover {
  background-color: #2853a9;
}

.btn-secondary {
  font-family: "Inter";
  font-size: 15px;
  font-weight: 500;
  color: #3366cc;
  outline: none;
  padding: 8px 40px;
  background-color: transparent;
  border: 2px solid #3366cc;
}

.btn-secondary:hover {
  color: #3366cc;
  background-color: #145dee0c;
  border: 2px solid #3366cc;
}

.mt-custom {
  margin-top: 145px;
}

.mt-custom-footer {
  margin-top: 145px;
}

.top-space {
  padding: 145px 0;
}

.title-spaces {
  margin-bottom: 90px;
}

.y-gutter-custom {
  row-gap: 70px;
}

.custom-h {
  height: 65px;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none !important;
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #3366cc;
  background-color: transparent;
  box-shadow: none;
  border-color: transparent;
}

.footer-menu li a {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
}

.mobile-custom h3 {
  font-size: 24px;
}

@media screen and (max-width: 991.98px) {
  .w-100-custom {
    width: 100% !important;
  }

  .btn-primary {
    padding: 5px 15px;
  }
  .navbar-brand img {
    height: 50px;
  }

  .custom-h {
    height: 50px;
  }
  .mt-custom {
    margin-top: 255px;
  }

  .mt-custom-footer {
    margin-top: 155px;
  }
  h1 {
    font-size: 2.7rem;
  }
}

@media screen and (max-width: 639px) {
  .customer-wraper {
    width: 140px;
  }

  .y-gutter-custom {
    row-gap: 20px;
  }

  .custom-h {
    height: auto;
    width: 175px;
  }
  .CustomHeight h1 {
    font-size: 37px;
  }
  .bigbillionimgContainer {
    position: absolute;
    top: 233px;
    right: 0;
  }
  .innerpageconatiner img {
    height: auto;
  }
  .bigbillionimgContainer img {
    height: auto;
  }

  .bgIMG {
    padding: 20px 0;
  }
  .bigB {
    width: 185px;
  }
}

@media screen and (max-width: 540px) {
  .navbar-brand img {
    height: 40px;
  }

  .btn-secondary {
    padding: 5px 5px;
  }

  .hero-img {
    height: 100%;
  }

  .mt-custom {
    margin-top: 430px;
  }
  .innerpageconatiner {
    position: unset !important;
    text-align: center;
    margin-top: -40px;
  }

  .mt-custom-footer {
    margin-top: 230px;
  }

  .CustomHeight {
    height: 430px;
  }

  .rowGap {
    row-gap: 50px;
  }

  .customer-wraper {
    width: 180px;
  }

  .top-mt {
    margin: 70px 0;
  }

  .top-space {
    padding: 70px 0;
  }

  .custom-forms {
    width: 100% !important;
  }
  .innerpageconatiner img {
    height: auto;
  }
  .bigbillionimgContainer img {
    height: auto;
  }
}
@media screen and (max-width: 414px) {
  .customer-wraper {
    width: 140px;
  }

  .custom-h {
    height: auto;
    width: 140px;
  }
}
