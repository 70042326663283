.modal-header{
    display: none;
}
.flag-input{
margin-left: -18px !important;
}
.mb-signup-form {
    margin-bottom: 35%;
}
.bg-gradients{
    background: linear-gradient(180deg, #FFFFFF 0%, #F0F5FF 100%);
}
.custom-forms{
    top: 20px;
}
.lsq-external-form-container{
    border: 1px solid #F1F1F1;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 0px 20px;
    width: 70% !important;
    min-width: 70%!important;
}
#lsq-form-modal.fullscreen{
    margin: 0 auto !important;
}
.lsq-external-form-container .form-group.mx-custom-textfield .mx-custom.form-control{
    height: 36px !important;
}
.lsq-form-theme-enabled.fullscreen.modal-v4.template-t1 .modal-footer button {
    background-color: #3366CC !important;
    color: white !important;
    font-size: 15px !important;
    font-family: 'Inter' !important;
    padding: 10px 68px !important;
    width: 100% !important;
    border-radius: 4px !important;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 20%) !important;
    margin-bottom: 20px !important;
}
.lsq-form-section-container .lsq-form-section-body .lsq-form-field-ctrl-text {
    font-family: 'Inter' !important;
}
.lsq-form-section-container .lsq-form-section-body .lsq-form-field-item.lsq-form-one-col{
       width: 100% !important;
}
.lsq-form-section-container .lsq-form-section-body .lsq-form-field-label{
    color: #000000 !important;
    font-size: 14px !important;
    font-family: 'Helvetica' !important;
    font-weight: 400 !important;
}
.lsq-form-field-wrapper .row-fluid:nth-child(1n+2){
    display: flex;
}
.lsq-form-select2-control-wrapper .select2-container .select2-selection--single span.select2-dropdown-clear-value{
    display: none;
}
.arrow-custom {
    transform: scale(2);
}

@media screen and (max-width: 991.98px) {
    .mb-signup-form {
        margin-bottom: 5%;
    }
    .custom-forms {
      position: static !important;
      margin: 0 auto;
    }
  .mb-signup-form {
    background-color: transparent !important;
  }
  .mb-signup-form h1,
  .mb-signup-form h3 {
    text-align: center;
  }
  .bigbillionimgContainer {
    position: absolute;
    bottom: 0;
    right: 0px;
}
.innerpageconatiner {
    position: absolute;
    bottom: 0;
    right: 0px;
}
}

@media screen and (max-width:639px) {
    .mb-signup-form {
        margin-bottom: 15%;
    }
    .mb-signup-form h1,
    .mb-signup-form h3 {
    text-align: left;
    }
    .lsq-external-form-container{
    width: 100% !important;
    min-width: 100%!important;
    }
    .list-unstyled {
        position: absolute;
        top: 510px;
    }
    .bottomMargin {
        bottom: 122px !important;
    }
  
}

